"use client";

import SendIcon from "@mui/icons-material/Send";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  Dialog,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cnmapMarketplace } from "../../../constants/buttons";
import { openInNewTab } from "../../../helpers/openInNewTab";
import useResponsive from "../../../hooks/useResponsive";

export default function Header({ onClick }) {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  const navigate = useNavigate();
  const [openVideoDialog, setOpenVideoDialog] = useState(false);

  // YouTube video ID extracted from the URL
  const videoId = "Xb03yAQ12Kk";

  const handleOpenVideo = () => {
    setOpenVideoDialog(true);
  };

  const handleCloseVideo = () => {
    setOpenVideoDialog(false);
  };

  return (
    <>
      <Grid container spacing={5} alignItems="center">
        <Grid item md={7} xs={12}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h2" sx={{ mb: 1 }}>
              Are you a nurse looking for a way to apply your clinical
              experience in the life sciences industry?
            </Typography>
            <Typography variant="h6">
              Become a Certified Nurse Medical Affairs Professional with CNMAP™
            </Typography>

            <Box sx={{ mt: 1 }}>
              <Typography variant="h6">
                ➤ Fast track your career & grow your earning potential
              </Typography>
              <Typography variant="h6" sx={{ mt: 1.5 }}>
                ➤ Join a rapidly growing field and impact patients on another
                level
              </Typography>
              <Typography variant="h6" sx={{ mt: 1.5 }}>
                ➤ More work from home opportunities with greater stability and
                quality of life
              </Typography>
              <Typography variant="h6" sx={{ mt: 1.5 }}>
                ➤ Earn 15 contact hours of continuing education credit while
                obtaining CNMAP
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: isDesktop ? "row" : "column",
              gap: isDesktop ? 2 : 2,
            }}
          >
            <Button
              variant="outlined"
              size="large"
              sx={{
                color: "white",
                border: "solid 1px white",
                width: !isDesktop ? "100%" : "45%",
                padding: "10px 16px",
              }}
              onClick={onClick}
            >
              Learn More About CNMAP™
            </Button>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              sx={{
                color: "#032d3a",
                backgroundColor: "white",
                width: !isDesktop ? "100%" : "45%",
                padding: "10px 16px",
                "&:hover": {
                  backgroundColor: "#FFF",
                },
              }}
              size="large"
              onClick={() => openInNewTab(cnmapMarketplace)}
            >
              Get Started Today
            </Button>
          </Box>
        </Grid>

        {/* Video Thumbnail - Now visible on both mobile and desktop */}
        <Grid
          item
          md={5}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: isDesktop ? 0 : 4, // Add margin top on mobile
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: isDesktop ? "750px" : "500px", // Smaller max width on mobile
              margin: "0 auto",
              transform: isDesktop ? "translateY(-10px)" : "none",
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingTop: "56.25%", // 16:9 aspect ratio
                overflow: "hidden",
                borderRadius: "12px",
                boxShadow: "0 8px 20px rgba(0,0,0,0.15)",
                border: "3px solid rgba(255,255,255,0.2)",
                cursor: "pointer",
              }}
              onClick={handleOpenVideo}
            >
              {/* Thumbnail Image */}
              <Box
                component="img"
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/Eva+Podcast+Cover.png"
                alt="CNMAP Video Thumbnail"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />

              {/* Play Button Overlay */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <PlayCircleIcon
                  sx={{
                    fontSize: isDesktop ? 100 : 80, // Slightly smaller on mobile
                    color: "white",
                    opacity: 0.9,
                    transition: "transform 0.2s ease, opacity 0.2s ease",
                    "&:hover": {
                      transform: "scale(1.1)",
                      opacity: 1,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Video Dialog */}
      <Dialog
        open={openVideoDialog}
        onClose={handleCloseVideo}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            paddingTop: "56.25%", // 16:9 aspect ratio
            backgroundColor: "#000",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <IconButton
            onClick={handleCloseVideo}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 1,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            ✕
          </IconButton>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0&controls=1&rel=0&modestbranding=1`}
            title="CNMAP Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Dialog>
    </>
  );
}
