"use client";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Link,
  Typography,
  Paper,
  Divider,
  Chip,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Avatar from "boring-avatars";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/ui/Footer";
import Spinner from "../../components/ui/Spinner";
import { formatBlogDate } from "../../helpers/formatBlogDate";
import useGetBlogPost from "../../hooks/useGetBlog";
import B2B from "./helpers/B2B";

export default function Blog() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { blogSlug } = useParams();
  const { entries, loading, error } = useGetBlogPost(blogSlug);

  if (loading) {
    return <Spinner />;
  }

  if (error || !entries) {
    return (
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Box>
          <Card elevation={3}>
            <CardContent sx={{ p: 4 }}>
              <Typography variant="h3" gutterBottom>
                Whoops, Blog not found
              </Typography>
              <Typography variant="body1">
                Please refresh the page or try another blog
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      sx={{ cursor: "pointer" }}
      onClick={(e) => navigate("/blogs")}
    >
      Blogs
    </Link>,
    <Typography key="3" color="text.primary">
      {entries.fields.blogTitle}
    </Typography>,
  ];

  const shareUrl = `https://nursetopharma.org/blog/${entries.fields.blogUrlSlug}`;

  return (
    <>
      <Helmet>
        <title>{entries.fields.blogTitle}</title>
        <meta name="description" content={entries.fields.blogMetaDescription} />

        <meta property="og:title" content={entries.fields.blogTitle} />
        <meta
          property="og:description"
          content={entries.fields.blogMetaDescription}
        />
        <meta
          property="og:image"
          content={entries.fields.blogImage.fields.file.url}
        />
        <meta
          property="og:url"
          content={`https://nursetopharma.org/blog/${entries.fields.blogUrlSlug}`}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={entries.fields.blogTitle} />
        <meta
          name="twitter:description"
          content={entries.fields.blogMetaDescription}
        />
        <meta
          name="twitter:image"
          content={entries.fields.blogImage.fields.file.url}
        />

        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://nursetopharma.org/blog/${entries.fields.blogUrlSlug}"
        },
        "headline": "${entries.fields.blogTitle}",
        "image": {
          "@type": "ImageObject",
          "url": "${entries.fields.blogImage.fields.file.url}"
        },
        "datePublished": "${entries.fields.blogUploadDate}",
        "dateModified": "${entries.fields.blogUploadDate}",
        "author": {
          "@type": "Person",
          "name": "${entries.fields.blogUploadedBy}"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Nurse to Pharma",
          "logo": {
            "@type": "ImageObject",
            "url": "https://nursetopharma.org/logo.png"
          }
        },
        "description": "${entries.fields.blogMetaDescription}"
      }
    `}
        </script>
      </Helmet>

      <Container maxWidth="lg">
        <Box sx={{ mt: 6, mb: 3 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>

        <Paper
          elevation={0}
          sx={{
            borderRadius: 2,
            overflow: "hidden",
            mb: 5,
            border: "1px solid rgba(0,0,0,0.08)",
          }}
        >
          <Box
            sx={{
              height: { xs: 250, md: 400 },
              width: "100%",
              position: "relative",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "rgba(0,0,0,0.03)",
            }}
          >
            <img
              src={
                entries.fields.blogImage.fields.file.url || "/placeholder.svg"
              }
              alt={entries.fields.blogTitle}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>

          <Box sx={{ p: { xs: 3, md: 5 } }}>
            <Typography
              variant={isMobile ? "h4" : "h3"}
              component="h1"
              sx={{
                fontWeight: 700,
                lineHeight: 1.2,
                mb: 4,
              }}
            >
              {entries.fields.blogTitle}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                justifyContent: "space-between",
                mb: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: { xs: 2, sm: 0 },
                }}
              >
                <Avatar
                  size={60}
                  name={entries.fields.blogUploadedBy}
                  variant="marble"
                  colors={[
                    "#92A1C6",
                    "#146A7C",
                    "#F0AB3D",
                    "#C271B4",
                    "#C20D90",
                  ]}
                />
                <Box sx={{ ml: 2 }}>
                  <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                    {entries.fields.blogUploadedBy}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 1,
                      mt: 0.5,
                    }}
                  >
                    <Chip
                      icon={<CalendarTodayIcon fontSize="small" />}
                      label={formatBlogDate(entries.fields.blogUploadDate)}
                      size="small"
                      variant="outlined"
                    />
                    <Chip
                      icon={<AccessTimeIcon fontSize="small" />}
                      label={`${entries.fields.blogEstimatedReadTime} min read`}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </Box>
              </Box>

              <Box>
                <Tooltip title="Share this article">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (navigator.share) {
                        navigator.share({
                          title: entries.fields.blogTitle,
                          text: entries.fields.blogMetaDescription,
                          url: shareUrl,
                        });
                      } else {
                        navigator.clipboard.writeText(shareUrl);
                        // You could add a snackbar notification here
                      }
                    }}
                  >
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            <Divider sx={{ mb: 4 }} />

            <Box
              sx={{
                typography: "body1",
                "& p": {
                  mb: 2,
                  lineHeight: 1.7,
                },
                "& h1, & h2, & h3, & h4, & h5, & h6": {
                  mt: 4,
                  mb: 2,
                  fontWeight: 700,
                },
                "& img": {
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: 1,
                  my: 3,
                },
                "& a": {
                  color: "primary.main",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                },
                "& ul, & ol": {
                  pl: 4,
                  mb: 2,
                },
                "& li": {
                  mb: 1,
                },
              }}
            >
              {documentToReactComponents(entries.fields.blogContent)}
            </Box>
          </Box>
        </Paper>

        <Box sx={{ mt: 5, mb: 10 }}>
          {entries.fields.blogEndCta &&
          entries.fields.blogEndCta === "B2B CTA" ? (
            <>
              <B2B />
            </>
          ) : (
            <>
              <B2B />
            </>
          )}
        </Box>
      </Container>
      <Footer />
    </>
  );
}
