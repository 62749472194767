"use client";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { formatBlogDate } from "../../../helpers/formatBlogDate";
import { Link, useNavigate } from "react-router-dom";
import { formatString } from "../../../helpers/formatString";

const CustomButton = styled(Button)(({ theme }) => ({
  minWidth: "180px",
  fontSize: "16px",
  borderRadius: "8px",
  fontWeight: "600",
  padding: "12px 24px",
  transition: "all 0.3s ease",
  background: theme.palette.primary.main,
  color: "white",
  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  "&:hover": {
    background: theme.palette.primary.dark,
    transform: "translateY(-2px)",
    boxShadow: "0 6px 15px rgba(0,0,0,0.15)",
  },
}));

export default function FeaturedBlog({ blog }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ mb: 6 }}>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          Featured Blog
        </Typography>
        <Box
          sx={{
            ml: 2,
            height: "4px",
            width: "60px",
            bgcolor: theme.palette.primary.main,
            borderRadius: "2px",
          }}
        />
      </Box>

      <Card
        elevation={3}
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          transition: "transform 0.3s, box-shadow 0.3s",
          "&:hover": {
            transform: "translateY(-8px)",
            boxShadow: "0 15px 30px rgba(0,0,0,0.1)",
          },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              position: "relative",
              height: { xs: "250px", md: "100%" },
              minHeight: { md: "400px" },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <img
                src={
                  blog.fields.blogImage.fields.file.url || "/placeholder.svg"
                }
                alt={blog.fields.blogTitle}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                loading="lazy"
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 16,
                left: 16,
                zIndex: 2,
              }}
            >
              <Chip
                label="Featured"
                color="primary"
                sx={{
                  fontWeight: "bold",
                  bgcolor: theme.palette.primary.main,
                  color: "white",
                  px: 1,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={7}>
            <CardContent
              sx={{
                p: { xs: 3, md: 4 },
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Chip
                  icon={<CalendarTodayIcon fontSize="small" />}
                  label={formatBlogDate(blog.fields.blogUploadDate)}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  icon={<AccessTimeIcon fontSize="small" />}
                  label={`${blog.fields.blogEstimatedReadTime} min read`}
                  size="small"
                  variant="outlined"
                />
              </Box>

              <Typography
                variant={isMobile ? "h5" : "h4"}
                component="h2"
                sx={{
                  fontWeight: 700,
                  mb: 3,
                  lineHeight: 1.2,
                  color: "text.primary",
                }}
              >
                <Link
                  to={`/blog/${blog.fields.blogUrlSlug}`}
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {blog.fields.blogTitle}
                </Link>
              </Typography>

              <Box
                sx={{
                  mb: 3,
                  typography: "body1",
                  color: "text.secondary",
                  flexGrow: 1,
                  "& p": {
                    margin: 0,
                    lineHeight: 1.7,
                  },
                }}
              >
                {documentToReactComponents(blog.fields.blogExcerpt)}
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                <PersonIcon
                  fontSize="small"
                  sx={{ mr: 1, color: "text.secondary" }}
                />
                <Typography sx={{ fontWeight: 600, color: "text.secondary" }}>
                  {formatString(blog.fields.blogUploadedBy, 30)}
                </Typography>
              </Box>

              <Box>
                <CustomButton
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => navigate(`/blog/${blog.fields.blogUrlSlug}`)}
                >
                  Read Featured Blog
                </CustomButton>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
