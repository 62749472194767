// import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
// import Label from "../../../components/label/Label";
// import { formatBlogDate } from "../../../helpers/formatBlogDate";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
// import { formatString } from "../../../helpers/formatString";
// import { Link } from "react-router-dom";

// export default function AllBlogs({ blogs }) {
//   return (
//     <Box>
//       <Box>
//         <Typography variant="h5" sx={{ fontWeight: 700 }}>
//           All Blogs
//         </Typography>
//       </Box>
//       <Box sx={{ mt: 3 }}>
//         <Grid container spacing={3}>
//           {blogs &&
//             blogs.length >= 1 &&
//             blogs.map((item, index) => (
//               <Grid item md={6} key={index}>
//                 <Card>
//                   <CardContent>
//                     <Grid container spacing={4}>
//                       <Grid
//                         item
//                         md={4}
//                         container
//                         className="flex justify-center align-center"
//                       >
//                         <img
//                           src={item.fields.blogImage.fields.file.url}
//                           alt={item.fields.blogTitle}
//                           style={{ borderRadius: "16px", height: "150px" }}
//                           loading="lazy"
//                         />
//                       </Grid>
//                       <Grid item md={8} container>
//                         <Box
//                           sx={{ width: "100%" }}
//                           className="flex justify-between align-center"
//                         >
//                           <Typography
//                             sx={{ fontWeight: 700, fontSize: "14px" }}
//                           >
//                             {formatBlogDate(item.fields.blogUploadDate)}
//                           </Typography>
//                         </Box>
//                         <Typography sx={{ fontWeight: 700, mt: 1 }}>
//                           <Link
//                             to={`/blog/${item.fields.blogUrlSlug}`}
//                             style={{ color: "black" }}
//                           >
//                             {item.fields.blogTitle}
//                           </Link>
//                         </Typography>
//                         <Box sx={{ mt: 1 }} className="flex align-center">
//                           <Typography>
//                             {formatString(item.fields.blogUploadedBy, 10)}
//                           </Typography>
//                           <Typography sx={{ ml: 1, mr: 1 }}>|</Typography>
//                           <Label>
//                             {item.fields.blogEstimatedReadTime} Minutes Read
//                           </Label>
//                         </Box>
//                         <Typography sx={{ mt: 1 }}>
//                           {formatString(
//                             documentToReactComponents(
//                               item.fields.blogExcerpt
//                             )[0].props.children[0],
//                             100
//                           )}
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//         </Grid>
//       </Box>
//     </Box>
//   );
// }

import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  CardActionArea,
  Divider,
  Avatar,
  Paper,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import { formatBlogDate } from "../../../helpers/formatBlogDate";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { formatString } from "../../../helpers/formatString";
import { Link } from "react-router-dom";

export default function AllBlogs({ blogs }) {
  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          All Blogs
        </Typography>
        <Divider sx={{ mt: 2 }} />
      </Box>
      <Box>
        <Grid container spacing={4}>
          {blogs &&
            blogs.length >= 1 &&
            blogs.map((item, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card
                  elevation={2}
                  sx={{
                    height: "100%",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      transform: "translateY(-8px)",
                      boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
                    },
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardActionArea
                    component={Link}
                    to={`/blog/${item.fields.blogUrlSlug}`}
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "stretch",
                      flexGrow: 1,
                    }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        height: 250,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        bgcolor: "rgba(0,0,0,0.03)",
                        borderBottom: "1px solid rgba(0,0,0,0.08)",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            item.fields.blogImage.fields.file.url ||
                            "/placeholder.svg"
                          }
                          alt={item.fields.blogTitle}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          loading="lazy"
                        />
                      </Box>
                    </Paper>
                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: 500,
                          }}
                        >
                          {formatBlogDate(item.fields.blogUploadDate)}
                        </Typography>
                        <Chip
                          icon={<AccessTimeIcon fontSize="small" />}
                          label={`${item.fields.blogEstimatedReadTime} min read`}
                          size="small"
                          color="primary"
                          variant="outlined"
                        />
                      </Box>

                      <Typography
                        variant="h6"
                        component="h2"
                        gutterBottom
                        sx={{
                          fontWeight: 700,
                          lineHeight: 1.3,
                          mb: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item.fields.blogTitle}
                      </Typography>

                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            mr: 1,
                            bgcolor: "primary.main",
                          }}
                        >
                          <PersonIcon fontSize="small" />
                        </Avatar>
                        <Typography variant="body2" color="text.secondary">
                          {formatString(item.fields.blogUploadedBy, 20)}
                        </Typography>
                      </Box>

                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {formatString(
                          documentToReactComponents(item.fields.blogExcerpt)[0]
                            ?.props?.children[0] || "",
                          150
                        )}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}
