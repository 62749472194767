import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function FAQsQuestions() {
  const theme = useTheme();

  return (
    <Container>
      <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
        Frequently Asked Questions
      </Typography>

      <Box sx={{ mt: 4 }}>
        {/* CNMAP™ Program Price? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>CNMAP™ Program Price?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The Certified Nurse Medical Affairs Professional costs $1,199.00
              USD. This includes 15 modules with pre and post-quizzes, and final
              exam.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Why should I get this certification? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Why should I get this certification?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Career progression for nurses with clinical experience" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Get in on the opportunity to power your career in a rapidly growing field" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Increase your earning potential" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Opportunity for nurses looking for a career that can offer flexibility in their work environment" />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        {/* 6. What sets CNMAP™ apart?
         */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What sets CNMAP™ apart?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nurses are critical thinkers and agile. They have all of the
              valued skills the life Sciences industry demands. CNMAP™
              establishes the pathway into life sciences roles for nurses and
              advance practice nurses. CNMAP™ is the first of its kind program
              to establish the industry standard for nurse professionals seeking
              entry into the industry, in various roles.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* 8. Who developed the CNMAP™ content?  */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Who developed the CNMAP™ content?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The content was developed by a group of nurse subject matter
              experts with extensive experience in both clinical settings and
              the life science industry.{" "}
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Is the CNMAP™ certification recognized outside of the United States?  */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {" "}
              Is the CNMAP™ certification recognized outside of the United
              States?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              All board certifications offered by the ACMA are internationally
              recognized, as we are accredited by the International Accreditors
              for Continuing Education & Training (IACET).
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* CNMAP™ Exam */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>CNMAP™ Exam</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The CNMAP™ exam is a 3-hour exam, taken online. The exam is
              multiple choice and contains 100 questions. 4 exam attempts are
              included in the CNMAP™ program cost, so there are no additional
              costs for retaking the CNMAP™ exam.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* How do I apply */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How Do I Apply?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              To apply, you must first register to{" "}
              <a href="https://www.acmamarketplace.org/">ACMA Marketplace</a>.
              Once there, add the certification to your cart and proceed to
              checkout. For assistance, please email us at{" "}
              <a href="mailto:info@acmainfo.org">info@acmainfo.org</a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* How Long Does the Program take to complete program */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How Long Does the Program take to complete?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Once you enroll in the CNMAP™ program, you have up to 1 month to
              complete the program. Since the entire program is online, you can
              work at your own pace & comfort level to complete the program.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* How Can I Access the Program Learning Content? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              How Can I Access the Program Learning Content?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Once you are accepted, you will receive confirmation and
              instructions via email. The program learning content can be
              accessed through an online, learning management system. You will
              have a total of six months to complete the CNMAP program.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* What professional designation would I be able to include in my
              title? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              What professional designation would I be able to include in my
              title?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              It will be a CNMAP™ professional designation. For example, Jane
              Doe, RN, CNMAP
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* If I fail the CNMAP™ exam, can I retake the exam? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              If I fail the CNMAP™ exam, can I retake the exam?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, you can retake the CNMAP™ exam up to 4 times. 4 exam attempts
              are included in the CNMAP™ program cost, so there are no
              additional costs for retaking the CNMAP™ exam. If you fail all 4
              attempts, there are additional costs required to be granted
              additional attempts.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* What if I fail the exam 4 times and have no more exam attempts? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              What if I fail the exam 4 times and have no more exam attempts?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              If a candidate fails the exam on the fourth try, there will be a
              waiting period of 1 year before retaking the exam up to four more
              times, and a reexamination fee of $250 USD.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* How will I receive my certificate? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How will I receive my certificate?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Certificates are available for download and print immediately
              after receiving passing results for the exam. Mailed certificates
              are available upon request via the United States Postal Service
              (USPS) for a fee of $ 27.00 USD. These certificates will be mailed
              quarterly. For example, to receive your certificate within the
              quarter you must complete your board exam within 10 days prior to
              the ending of that quarter. Q1: January-March, Q2: April-Jun, Q3:
              Jul-Sep, Q4: Oct-Dec. Candidates may request for an alternate
              shipping method for an additional charge.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* How soon will I receive the exam results? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How soon will I receive the exam results?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Results will be available right away.</Typography>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Will I be able to review the exam results?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              In order to protect the integrity of the CNMAP™ program, no exam
              questions, nor the answer key, will be released to any individual
              or organization.
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        {/* Will I be able to review the exam results? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Will I be able to review the exam results?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Your final score will be available to you. In order to protect the
              integrity of the CNMAP™ program, no exam questions, nor the answer
              key, will be released to any individual or organization.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* When and where can I obtain CE credits for renewal of my CNMAP™
              certification? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              When and where can I obtain CE credits for renewal of my CNMAP™
              certification?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              CE credits will be completed via the ACMA's learning management
              system similar to the modules of the CNMAP™ program. CE's cannot
              be completed any sooner than 12 months before the renewal date,
              and, therefore detailed instructions will be sent as you get
              closer to your renewal date.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* What happens if I need a program extension? */}
        {/* <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What happens if I need a program extension?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              If any extensions are needed, a charge of $399.99 USD will be
              required, regardless of extension period time frame.
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        {/* How do I recertify or maintain my CNMAP™ certification? */}
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {" "}
              How do I recertify or maintain my CNMAP™ certification?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The CNMAP™ credential is valid for 5 years. To keep your
              certification active, here is the path: Retaking and passing the
              exam or completing 10 hours of continuing education modules
              provided by the ACMA and successful quiz completion of each
              module. The cost for recertification is $399 USD.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
